<template>
  <span v-if="drawer" class="drawer"
    ><v-card
      v-if="navigation"
      :class="cardData.class"
      elevation="0"
      class="pa-2 my-1 d-flex align-center justify-space-between"
      :to="{ name: cardData.routeName }"
      ><div class="d-flex align-center">
        <v-icon color="primary" v-if="cardData.icon">{{
          `$${cardData.icon}`
        }}</v-icon>

        <div class="ml-2">{{ $t(cardData.title) }}</div>
      </div>
      <v-icon color="primary" small>$chevronRight</v-icon></v-card
    ><v-card
      v-else
      @click.stop="doLogout"
      :class="cardData.class"
      elevation="0"
      class="pa-2 my-1 white d-flex align-center"
      ><v-icon color="black" v-if="cardData.icon">{{
        `$${cardData.icon}`
      }}</v-icon>
      <div class="ml-2">{{ $t(cardData.title) }}</div></v-card
    ></span
  >
  <span v-else>
    <v-card
      v-if="navigation"
      :class="cardData.class"
      elevation="0"
      class="pa-3 ma-2"
      :to="{ name: cardData.routeName }"
    >
      <div class="card-icon">
        <v-icon color="primary" large v-if="cardData.icon">{{
          `$${cardData.icon}`
        }}</v-icon>
      </div>
      <v-card-title class="pa-0">
        {{ $t(cardData.title) }}
      </v-card-title>
      <v-card-subtitle class="mt-1 pa-0">
        <div
          class="description text-body-2 text-left"
          v-if="cardData.description"
        >
          {{ $t(cardData.description) }}
        </div>
      </v-card-subtitle>
      <v-card-actions class="d-flex justify-end">
        <v-icon color="primary">$chevronRight</v-icon>
      </v-card-actions>
    </v-card>
    <v-card
      v-else
      @click.stop="doLogout"
      :class="cardData.class"
      elevation="0"
      class="py-3 my-2 white d-flex d-logout"
    >
      <v-card-title class="pa-0">
        <v-icon color="black" class="mx-3" large v-if="cardData.icon">{{
          `$${cardData.icon}`
        }}</v-icon>
        {{ $t(cardData.title) }}
      </v-card-title>
    </v-card>
  </span>
</template>

<style scoped lang="scss">
.v-card {
  height: 200px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: inherit;
    display: flex;
  }
  border-radius: 8px;
  border: 1px solid var(--v-grey-lighten2);
  background-color: var(--v-grey-lighten2);
  .v-card__title {
    font-size: 26px;
    font-weight: 600;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      font-size: 14px;
    }
  }
  .v-card__subtitle {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      display: none;
    }
  }
  .description {
    font-size: 14px;
  }
  .v-card__actions {
    position: absolute;
    bottom: 4px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      bottom: 6px;
    }
    right: 1px;
    a {
      color: var(--v-primary-base);
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
    }
  }
  .card-icon {
    min-height: 52px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-height: unset !important;
      padding-right: 12px !important;
      i {
        font-size: 22px !important;
      }
    }
  }
}
.invite {
  background-color: white;
  border-color: $primary;
}
.drawer {
  .v-card {
    height: 65px;
    font-weight: 600;
  }
}
.is-cordova {
  .d-logout {
    height: 65px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 24px !important;
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  name: "DashboardCard",
  props: {
    cardData: { type: Object, required: true },
    navigation: { type: Boolean, required: true },
    drawer: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>
